// // src/components/ProtectedRoute.js
// import { useState, useEffect } from 'react';
// import { Navigate } from 'react-router-dom';
// import { verifyToken } from '../api';

// const ProtectedRoute = ({ children }) => {
//   const [isLoading, setIsLoading] = useState(true);
//   const [isValid, setIsValid] = useState(false);

//   useEffect(() => {
//     const checkToken = async () => {
//       const valid = await verifyToken();
//       setIsValid(valid);
//       setIsLoading(false);
//     };

//     checkToken();
//   }, []);

//   if (isLoading) {
//     return <div>Loading...</div>; // Or any loading indicator
//   }

//   if (!isValid) {
//     return <Navigate to="/login" replace />;
//   }

//   return children;
// };

// export default ProtectedRoute;

import { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { verifyToken, authenticatedRequest } from '../api';

const ProtectedRoute = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [authState, setAuthState] = useState({
    isAuthenticated: false,
    isSubscribed: false,
    isWalletUser: false
  });

  useEffect(() => {
    const checkAccess = async () => {
      try {
        // First check if token is valid
        const isValidToken = await verifyToken();
        
        if (!isValidToken) {
          console.log('Token validation failed');
          setAuthState({
            isAuthenticated: false,
            isSubscribed: false,
            isWalletUser: false
          });
          setIsLoading(false);
          return;
        }
        console.log('Token is valid, getting user ID...');
        console.log('Current token:', localStorage.getItem('token'));

        // If token is valid, get user ID and then details from Gogettr
        const userIdResponse = await authenticatedRequest.get('/auth/get-user-id');
        console.log('User ID response:', userIdResponse.data);
        console.log('About to request user details for ID:', userIdResponse.data.userId);
        const userResponse = await authenticatedRequest.get(
          `https://peacepal-gogettr-c2775e5dd645.herokuapp.com/users/${userIdResponse.data.userId}`
        );
        
        setAuthState({
          isAuthenticated: true,
          isSubscribed: userResponse.data.activeSub === 'active',
          isWalletUser: userResponse.data.isWalletUser || false
        });
        
      } catch (error) {
        console.error('Detailed error:', {
            status: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data,
            headers: error.response?.headers
        });
        console.error('Error checking access:', error);
        setAuthState({
          isAuthenticated: false,
          isSubscribed: false,
          isWalletUser: false
        });
      } finally {
        setIsLoading(false);
      }
    };

    checkAccess();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="text-white">Loading...</div>
      </div>
    );
  }

  // If not authenticated, redirect to login
  if (!authState.isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  // If authenticated but not subscribed
  if (!authState.isSubscribed) {
    // Redirect based on user type
    if (authState.isWalletUser) {
      return <Navigate to="/pay_web3" replace />;
    } else {
      return <Navigate to="/pay" replace />;
    }
  }

  // If both authenticated and subscribed, render children
  return children;
};

export default ProtectedRoute;