import React, { useState, useEffect, useRef } from 'react';
import { ArrowLeft, Menu, MessageSquare, Copy, Mic, Home, ChevronRight, Volume2, VolumeX } from 'lucide-react';
import { Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenuToggle, NavbarMenu, NavbarMenuItem, Link, Button, Avatar } from "@nextui-org/react";
import axios from 'axios';
import { authenticatedRequest } from '../../api';
import { useSearchParams, useNavigate } from 'react-router-dom';
import peter from "../../../public/pal_peter_animated.gif";
import shonna from "../../../public/pal_shonna_animated.gif";
import satoshi from "../../../public/pal_satoshi_animated.gif";
import genie from "../../../public/pal_genie_animated.gif";

const Chat = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedCharacter = searchParams.get('character') || "Peter";

  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [conversationId, setConversationId] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const [userId, setUserId] = useState(null);
  const [palId, setPalId] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [streamingText, setStreamingText] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  
  const chatEndRef = useRef(null);
  const audioRef = useRef(null);

  const voiceIds = {
    Peter: "JBFqnCBsd6RMkjVDRZzb",
    Genie: "zcAOhNBS3c14rBihAFp1",
    Satoshi: "3JDquces8E8bkmvbh6Bc",
    Shonna: "03vEurziQfq3V8WZhQvn"
  };

  const streamText = async (text) => {
    setIsStreaming(true);
    const words = text.split(' ');
    let currentText = '';
    
    for (let word of words) {
      currentText += word + ' ';
      setStreamingText(currentText);
      // Increased from 50ms to 100ms for slower typing
      await new Promise(resolve => setTimeout(resolve, 125));
    }
    setIsStreaming(false);
    return currentText.trim();
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleHomeClick = () => {
    navigate('/home');
  };

//   const generateAndPlaySpeech = async (text, voiceId) => {
//     if (isMuted) return Promise.resolve();
    
//     try {
//       const response = await fetch(`https://api.elevenlabs.io/v1/text-to-speech/${voiceId}`, {
//         method: 'POST',
//         headers: {
//           'Accept': 'audio/mpeg',
//           'xi-api-key': 'sk_c38f593cc53c260a990040cf3df36c44bb4ac2c8990515de',
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//           text,
//           model_id: "eleven_monolingual_v1",
//           voice_settings: {
//             stability: 0.5,
//             similarity_boost: 0.75
//           }
//         }),
//       });

//       if (!response.ok) {
//         throw new Error(`HTTP error! status: ${response.status}`);
//       }

//       if (!audioRef.current) {
//         return Promise.reject(new Error('Audio element not found'));
//       }

//       const audioBlob = await response.blob();
//       const audioUrl = URL.createObjectURL(audioBlob);
      
//       audioRef.current.src = audioUrl;
//       setIsPlaying(true);
      
//       const playPromise = audioRef.current.play();
      
//       playPromise.then(() => {
//         const previousUrl = audioRef.current.dataset.previousUrl;
//         if (previousUrl) {
//           URL.revokeObjectURL(previousUrl);
//         }
//         audioRef.current.dataset.previousUrl = audioUrl;
//       });

//       return playPromise;

//     } catch (error) {
//       console.error('Error generating speech:', error);
//       return Promise.reject(error);
//     }
// };

const generateAndPlaySpeech = async (text, voiceId) => {
  if (isMuted) return Promise.resolve();
  
  try {
    const response = await authenticatedRequest.post(
      'https://peacepal-gogettr-c2775e5dd645.herokuapp.com/generate-speech',
      { text, voiceId },
      { responseType: 'blob' }
    );

    if (!audioRef.current) {
      return Promise.reject(new Error('Audio element not found'));
    }

    const audioUrl = URL.createObjectURL(response.data);
    audioRef.current.src = audioUrl;
    setIsPlaying(true);
    
    const playPromise = audioRef.current.play();
    
    playPromise.then(() => {
      const previousUrl = audioRef.current.dataset.previousUrl;
      if (previousUrl) {
        URL.revokeObjectURL(previousUrl);
      }
      audioRef.current.dataset.previousUrl = audioUrl;
    });

    return playPromise;

  } catch (error) {
    console.error('Error generating speech:', error);
    return Promise.reject(error);
  }
};

  const getUserId = async () => {
    try {
      const response = await authenticatedRequest.get("/auth/get-user-id");
      return response.data.userId;
    } catch (error) {
      console.error('Error getting user ID:', error);
      return null;
    }
  };

  const getPalId = async (characterName) => {
    try {
      const response = await authenticatedRequest.get(
        `https://peacepal-gogettr-c2775e5dd645.herokuapp.com/pal/${characterName}`
      );
      return response.data.palId;
    } catch (error) {
      console.error('Error getting pal ID:', error);
      return null;
    }
  };

  useEffect(() => {
    const initializeChat = async () => {
      const validCharacters = ["Peter", "Genie", "Satoshi", "Shonna"];
      
      if (!validCharacters.includes(selectedCharacter)) {
        console.log(`Invalid character "${selectedCharacter}" requested, redirecting to Peter`);
        navigate('/chat?character=Peter', { replace: true });
        return;
      }

      try {
        const [newUserId, newPalId] = await Promise.all([
          getUserId(),
          getPalId(selectedCharacter)
        ]);

        setUserId(newUserId);
        setPalId(newPalId);

        if (newUserId && newPalId) {
          loadChatHistory(newUserId, newPalId);
        }
      } catch (error) {
        console.error('Error initializing chat:', error);
        setIsLoadingHistory(false);
      }
    };

    initializeChat();
  }, [selectedCharacter, navigate]);

  const loadChatHistory = async (currentUserId, currentPalId) => {
    if (!currentUserId || !currentPalId) return;
    
    try {
      const url = `https://peacepal-gogettr-c2775e5dd645.herokuapp.com/conversations/${currentUserId}/${currentPalId}`;
      const response = await authenticatedRequest.get(url);
      setChatHistory(response.data);
      if (response.data.conversationId) {
        setConversationId(response.data.conversationId);
      }
    } catch (error) {
      if (error.response?.status === 401) {
        // Token expired/invalid - user will be redirected by the authenticatedRequest interceptor
        return;
      }
      if (error.response?.status === 403) {
        // User trying to access unauthorized conversation
        navigate('/home');
        return;
      }
      console.error('Error loading chat history:', error.response?.data?.message || 'Failed to load chat history');
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const handleSend = async () => {
    if (message.trim() === '' || !userId || !palId || isProcessing) return;

    const userMessage = message.trim();
    const newUserMessage = { type: 'user', content: userMessage };
    const url = `https://peacepal-gogettr-c2775e5dd645.herokuapp.com/conversations/${userId}/${palId}`;

    try {
      setIsProcessing(true);
      setIsLoading(true);
      setMessage('');
      setStreamingText('');

      // Start user message update
      const userMessageUpdate = authenticatedRequest.patch(url, { 
        message: newUserMessage 
      });

      setChatHistory(prev => [...prev, newUserMessage]);

      // Get AI response
      const aiResponse = await axios.post('https://peacepal-chatbot-29249eeda190.herokuapp.com/chat', {
        message: userMessage,
        conversationId,
        userId,
        palId
      });

      // Start voice generation IMMEDIATELY after getting AI response
      // Don't wait for streaming or anything else
      const voiceId = voiceIds[selectedCharacter];
      const voicePromise = voiceId && !isMuted ? 
        generateAndPlaySpeech(aiResponse.data.message, voiceId) : 
        Promise.resolve();

      // Start AI message DB update in parallel
      const aiMessageUpdate = authenticatedRequest.patch(url, { 
        message: { type: 'ai', content: aiResponse.data.message }
      });

      // Stream text while voice is being generated
      await streamText(aiResponse.data.message);

      // After streaming completes, update chat history
      const newAIMessage = { type: 'ai', content: aiResponse.data.message };
      setChatHistory(prev => [...prev, newAIMessage]);

      // Wait for all background operations to complete
      await Promise.all([
        userMessageUpdate,
        voicePromise,
        aiMessageUpdate
      ]).catch(error => {
        console.error('Non-critical error:', error);
      });

      if (!conversationId && aiResponse.data.conversationId) {
        setConversationId(aiResponse.data.conversationId);
      }

    } catch (error) {
      // New error handling
      if (error.response?.status === 401) {
        // Will be handled by authenticatedRequest interceptor
        return;
      }
      if (error.response?.status === 403) {
        navigate('/home');
        return;
      }
      console.error('Error sending message:', error);
      setChatHistory(prev => [...prev, { 
        type: 'error', 
        content: 'Failed to get response. Please try again.' 
      }]);
    } finally {
      setIsProcessing(false);
      setIsLoading(false);
      setStreamingText('');
    }
  };

  const getCharacterImage = () => {
    switch(selectedCharacter) {
      case "Genie":
        return genie;
      case "Satoshi":
        return satoshi;
      case "Shonna":
        return shonna;
      case "Peter":
      default:
        return peter;
    }
  };

  const startListening = () => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setMessage(prevMessage => prevMessage + ' ' + transcript);
      };

      recognition.onerror = (event) => {
        console.error('Speech recognition error', event.error);
        setIsListening(false);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognition.start();
    } else {
      alert('Speech recognition is not supported in your browser.');
    }
  };

  const renderChatMessages = () => (
    <>
      {chatHistory.map((msg, index) => (
        <div key={index} className="flex justify-center">
          <div className={`rounded-lg p-3 ${
            msg.type === 'user' ? 'bg-gray-800 max-w-xs' : 
            msg.type === 'ai' ? 'bg-gradient-to-r from-blue-400 to-purple-500 max-w-md' :
            'bg-red-500 max-w-xs'
          }`}>
            <p className="text-white">{msg.content}</p>
          </div>
        </div>
      ))}
      {isStreaming && streamingText && (
        <div className="flex justify-center">
          <div className="bg-gradient-to-r from-blue-400 to-purple-500 rounded-lg p-3 max-w-md">
            <p className="text-white">
              {streamingText}
              <span className="animate-pulse">▊</span>
            </p>
          </div>
        </div>
      )}
      {isLoading && !isStreaming && (
        <div className="flex justify-center">
          <div className="bg-gray-700 rounded-lg p-3">
            <p className="text-white">Thinking...</p>
          </div>
        </div>
      )}
    </>
  );

  useEffect(scrollToBottom, [chatHistory, streamingText]);

  if (isLoadingHistory || !userId || !palId) {
    return (
      <div className="flex flex-col h-screen bg-gray-900 text-white font-sans items-center justify-center">
        <div className="animate-pulse">Loading chat...</div>
      </div>
    );
  }

  const renderAudioControls = () => (
    <div className="flex items-center gap-2">
      <button
        onClick={() => setIsMuted(!isMuted)}
        className="p-2 rounded-full hover:bg-purple-800 transition-colors bg-purple-500"
      >
        {isMuted ? (
          <VolumeX className="text-white" size={20} />
        ) : (
          <Volume2 className="text-white" size={20} />
        )}
      </button>
    </div>
  );

  return (
    <div className="flex flex-col h-screen bg-gray-900">
      <div className="flex-none">
        <div className="flex items-center justify-between p-4 bg-gray-900">
          <div className="flex items-center">
            <Home 
              className="mr-4 text-gray-400 cursor-pointer hover:text-gray-200 transition-colors" 
              onClick={handleHomeClick}
            />
            <ChevronRight className="mr-4 text-gray-500" size={20} />
            <h1 className="text-xl text-white">Chat with {selectedCharacter}</h1>
          </div>
          <div className="flex items-center gap-4">
            {renderAudioControls()}
            {/* <Button className="bg-purple-500 text-white px-6 py-2 rounded-full transition-all duration-300 ease-in-out shadow-[0_0_10px_2px_rgba(168,85,247,0.4)] hover:shadow-[0_0_15px_3px_rgba(168,85,247,0.6)]">
              UPGRADE
            </Button> */}
          </div>
        </div>
        <div className="h-1 bg-gradient-to-r from-[#54a3c3] to-[#a16bdd]" />
      </div>

      <div className="flex flex-1 min-h-0">
        <div className="hidden md:flex md:w-1/3 items-center justify-center p-0 border-r border-gray-800">
          <img
            src={getCharacterImage()}
            alt={`${selectedCharacter} Character`}
            className="max-h-full w-auto object-contain"
          />
        </div>

        <div className="w-full md:w-2/3 flex flex-col min-h-0">
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {renderChatMessages()}
            <div ref={chatEndRef} />
          </div>

          <div className="flex-none p-4 bg-gray-900">
            <div className="flex items-center bg-gray-800 rounded-lg">
              <input
                type="text"
                placeholder="What's on your mind?"
                className="flex-grow bg-transparent p-3 outline-none text-gray-300"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                style={{ fontSize: '16px' }}
              />
              <button
                className={`p-2 rounded-full m-1 ${isListening ? 'bg-red-500' : 'hover:bg-purple-800 bg-purple-500'}`}
                onClick={startListening}
                >
              <Mic size={20} className="text-white" />
              </button>
              <button
                className={`bg-gradient-to-r from-blue-400 to-purple-500 text-white px-4 py-2 rounded-lg m-1 ${
                isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:opacity-90'
                }`}
                onClick={handleSend}
                disabled={isProcessing || isLoading}
              >
                {isProcessing ? 'Processing...' : 'Send'}
              </button>
            </div>
          </div>
        </div>
      </div>
      <audio ref={audioRef} onEnded={() => setIsPlaying(false)} />
    </div>
  );
};

export default Chat;